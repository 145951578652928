@import "https://rsms.me/inter/inter.css";

/* styles/color.css */
:root {
  --puck-color-rose-01: #4a001c;
  --puck-color-rose-02: #670833;
  --puck-color-rose-03: #87114c;
  --puck-color-rose-04: #a81a66;
  --puck-color-rose-05: #bc5089;
  --puck-color-rose-06: #cc7ca5;
  --puck-color-rose-07: #d89aba;
  --puck-color-rose-08: #e3b8cf;
  --puck-color-rose-09: #efd6e3;
  --puck-color-rose-10: #f6eaf1;
  --puck-color-rose-11: #faf4f8;
  --puck-color-rose-12: #fef8fc;
  --puck-color-azure-01: #00175d;
  --puck-color-azure-02: #002c77;
  --puck-color-azure-03: #014292;
  --puck-color-azure-04: #0158ad;
  --puck-color-azure-05: #3479be;
  --puck-color-azure-06: #6499cf;
  --puck-color-azure-07: #88b0da;
  --puck-color-azure-08: #abc7e5;
  --puck-color-azure-09: #cfdff0;
  --puck-color-azure-10: #e7eef7;
  --puck-color-azure-11: #f3f6fb;
  --puck-color-azure-12: #f7faff;
  --puck-color-green-01: #002000;
  --puck-color-green-02: #043604;
  --puck-color-green-03: #084e08;
  --puck-color-green-04: #0c680c;
  --puck-color-green-05: #1d882f;
  --puck-color-green-06: #2faa53;
  --puck-color-green-07: #56c16f;
  --puck-color-green-08: #7dd78b;
  --puck-color-green-09: #b8e8bf;
  --puck-color-green-10: #ddf3e0;
  --puck-color-green-11: #eff8f0;
  --puck-color-green-12: #f3fcf4;
  --puck-color-yellow-01: #211000;
  --puck-color-yellow-02: #362700;
  --puck-color-yellow-03: #4c4000;
  --puck-color-yellow-04: #645a00;
  --puck-color-yellow-05: #877614;
  --puck-color-yellow-06: #ab9429;
  --puck-color-yellow-07: #bfac4e;
  --puck-color-yellow-08: #d4c474;
  --puck-color-yellow-09: #e6deb1;
  --puck-color-yellow-10: #f3efd9;
  --puck-color-yellow-11: #f9f7ed;
  --puck-color-yellow-12: #fcfaf0;
  --puck-color-red-01: #4c0000;
  --puck-color-red-02: #6a0a10;
  --puck-color-red-03: #8a1422;
  --puck-color-red-04: #ac1f35;
  --puck-color-red-05: #bf5366;
  --puck-color-red-06: #ce7e8e;
  --puck-color-red-07: #d99ca8;
  --puck-color-red-08: #e4b9c2;
  --puck-color-red-09: #efd7db;
  --puck-color-red-10: #f6eaec;
  --puck-color-red-11: #faf4f5;
  --puck-color-red-12: #fff9fa;
  --puck-color-grey-01: #181818;
  --puck-color-grey-02: #292929;
  --puck-color-grey-03: #404040;
  --puck-color-grey-04: #5a5a5a;
  --puck-color-grey-05: #767676;
  --puck-color-grey-06: #949494;
  --puck-color-grey-07: #ababab;
  --puck-color-grey-08: #c3c3c3;
  --puck-color-grey-09: #dcdcdc;
  --puck-color-grey-10: #efefef;
  --puck-color-grey-11: #f5f5f5;
  --puck-color-grey-12: #fafafa;
  --puck-color-black: #000000;
  --puck-color-white: #ffffff;
}

/* styles/typography.css */
:root {
  --puck-font-size-scale-base-unitless: 12;
  --puck-font-size-xxxs-unitless: 12;
  --puck-font-size-xxs-unitless: 14;
  --puck-font-size-xs-unitless: 16;
  --puck-font-size-s-unitless: 18;
  --puck-font-size-m-unitless: 21;
  --puck-font-size-l-unitless: 24;
  --puck-font-size-xl-unitless: 28;
  --puck-font-size-xxl-unitless: 36;
  --puck-font-size-xxxl-unitless: 48;
  --puck-font-size-xxxxl-unitless: 56;
  --puck-font-size-xxxs: calc(1rem * var(--puck-font-size-xxxs-unitless) / 16);
  --puck-font-size-xxs: calc(1rem * var(--puck-font-size-xxs-unitless) / 16);
  --puck-font-size-xs: calc(1rem * var(--puck-font-size-xs-unitless) / 16);
  --puck-font-size-s: calc(1rem * var(--puck-font-size-s-unitless) / 16);
  --puck-font-size-m: calc(1rem * var(--puck-font-size-m-unitless) / 16);
  --puck-font-size-l: calc(1rem * var(--puck-font-size-l-unitless) / 16);
  --puck-font-size-xl: calc(1rem * var(--puck-font-size-xl-unitless) / 16);
  --puck-font-size-xxl: calc(1rem * var(--puck-font-size-xxl-unitless) / 16);
  --puck-font-size-xxxl: calc(1rem * var(--puck-font-size-xxxl-unitless) / 16);
  --puck-font-size-xxxxl: calc( 1rem * var(--puck-font-size-xxxxl-unitless) / 16 );
  --puck-font-size-base: var(--puck-font-size-xs);
  --line-height-reset: 1;
  --line-height-xs: calc( var(--space-m-unitless) / var(--puck-font-size-m-unitless) );
  --line-height-s: calc( var(--space-m-unitless) / var(--puck-font-size-s-unitless) );
  --line-height-m: calc( var(--space-m-unitless) / var(--puck-font-size-xs-unitless) );
  --line-height-l: calc( var(--space-m-unitless) / var(--puck-font-size-xxs-unitless) );
  --line-height-xl: calc( var(--space-m-unitless) / var(--puck-font-size-scale-base-unitless) );
  --line-height-base: var(--line-height-m);
  --fallback-font-stack:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Helvetica Neue,
    sans-serif,
    Apple Color Emoji,
    Segoe UI Emoji,
    Segoe UI Symbol;
  --puck-font-family: Inter, var(--fallback-font-stack);
  --puck-font-family-monospaced:
    ui-monospace,
    "Cascadia Code",
    "Source Code Pro",
    Menlo,
    Consolas,
    "DejaVu Sans Mono",
    monospace;
}
@supports (font-variation-settings: normal) {
  :root {
    --puck-font-family: InterVariable, var(--fallback-font-stack);
  }
}

/* styles.css */

/* css-module:/home/runner/work/puck/puck/packages/core/components/Button/Button.module.css/#css-module-data */
._Button_8fn3a_1 {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: none;
  border: 1px solid transparent;
  border-radius: 4px;
  color: var(--puck-color-white);
  display: inline-flex;
  align-items: center;
  gap: 8px;
  letter-spacing: 0.05ch;
  font-family: var(--puck-font-family);
  font-size: 14px;
  font-weight: 400;
  box-sizing: border-box;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  transition: background-color 50ms ease-in;
  cursor: pointer;
  white-space: nowrap;
  margin: 0;
}
._Button_8fn3a_1:hover,
._Button_8fn3a_1:active {
  transition: none;
}
._Button--medium_8fn3a_29 {
  min-height: 34px;
  padding-bottom: 7px;
  padding-left: 19px;
  padding-right: 19px;
  padding-top: 7px;
}
._Button--large_8fn3a_37 {
  padding-bottom: 11px;
  padding-left: 19px;
  padding-right: 19px;
  padding-top: 11px;
}
._Button-icon_8fn3a_44 {
  margin-top: 2px;
}
._Button--primary_8fn3a_48 {
  background: var(--puck-color-azure-04);
}
._Button_8fn3a_1:focus-visible {
  outline: 2px solid var(--puck-color-azure-05);
  outline-offset: 2px;
}
@media (hover: hover) and (pointer: fine) {
  ._Button--primary_8fn3a_48:hover {
    background-color: var(--puck-color-azure-03);
  }
}
._Button--primary_8fn3a_48:active {
  background-color: var(--puck-color-azure-02);
}
._Button--secondary_8fn3a_67 {
  border: 1px solid var(--puck-color-grey-01);
  color: var(--puck-color-black);
}
@media (hover: hover) and (pointer: fine) {
  ._Button--secondary_8fn3a_67:hover {
    background-color: var(--puck-color-azure-12);
  }
}
._Button--secondary_8fn3a_67:active {
  background-color: var(--puck-color-azure-11);
}
._Button--flush_8fn3a_82 {
  border-radius: 0;
}
._Button--disabled_8fn3a_86,
._Button--disabled_8fn3a_86:hover {
  background-color: var(--puck-color-grey-07);
  color: var(--puck-color-grey-03);
  cursor: not-allowed;
}
._Button--fullWidth_8fn3a_93 {
  justify-content: center;
  width: 100%;
}
._Button-spinner_8fn3a_98 {
  padding-left: 8px;
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/Drawer/styles.module.css/#css-module-data */
._Drawer_6zh0b_1 {
  font-family: var(--puck-font-family);
}
._DrawerItem-default_6zh0b_5 ._DrawerItem-draggableWrapper_6zh0b_5 {
  padding-bottom: 12px;
}
._DrawerItem_6zh0b_5:last-of-type ._DrawerItem-default_6zh0b_5 ._DrawerItem-draggableWrapper_6zh0b_5 {
  padding-bottom: 0;
}
._DrawerItem-draggable_6zh0b_5 {
  background: var(--puck-color-white);
  padding: 12px;
  display: flex;
  border: 1px var(--puck-color-grey-09) solid;
  border-radius: 4px;
  font-size: var(--puck-font-size-xxs);
  justify-content: space-between;
  align-items: center;
  cursor: grab;
  transition: background-color 50ms ease-in, color 50ms ease-in;
}
._DrawerItem_6zh0b_5:focus-visible {
  outline: 0;
}
._Drawer_6zh0b_1:not(._Drawer--isDraggingFrom_6zh0b_31) ._DrawerItem_6zh0b_5:focus-visible ._DrawerItem-draggable_6zh0b_5 {
  border-radius: 4px;
  outline: 2px solid var(--puck-color-azure-05);
  outline-offset: 2px;
}
@media (hover: hover) and (pointer: fine) {
  ._Drawer_6zh0b_1:not(._Drawer--isDraggingFrom_6zh0b_31) ._DrawerItem-draggable_6zh0b_5:hover {
    background-color: var(--puck-color-azure-12);
    color: var(--puck-color-azure-04);
    transition: none;
  }
}
._DrawerItem-name_6zh0b_47 {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/DragIcon/styles.module.css/#css-module-data */
._DragIcon_1p5wn_1 {
  color: var(--puck-color-grey-05);
  cursor: grab;
  padding: 4px;
  border-radius: 4px;
}
@media (hover: hover) and (pointer: fine) {
  ._DragIcon_1p5wn_1:hover {
    color: var(--puck-color-azure-05);
    background-color: var(--puck-color-azure-12);
  }
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/DraggableComponent/styles.module.css/#css-module-data */
._DraggableComponent_1vpvt_1 {
  outline-offset: 0px !important;
  pointer-events: auto;
}
._DraggableComponent_1vpvt_1:focus-visible {
  outline: 2px solid var(--puck-color-azure-05);
}
._DraggableComponent--isDragging_1vpvt_10 {
  background: color-mix(in srgb, var(--puck-color-azure-08) 10%, transparent);
  outline: 2px var(--puck-color-azure-09) solid !important;
  overflow: hidden;
}
._DraggableComponent-contents_1vpvt_16 {
  position: relative;
  pointer-events: none;
  z-index: 0;
}
._DraggableComponent-contents_1vpvt_16::before,
._DraggableComponent-contents_1vpvt_16::after {
  content: " ";
  display: table;
}
._DraggableComponent-overlay_1vpvt_29 {
  display: none;
  background: color-mix(in srgb, var(--puck-color-azure-08) 30%, transparent);
  cursor: pointer;
  height: 100%;
  width: 100%;
  top: 0;
  position: absolute;
  z-index: 0;
  pointer-events: none;
  box-sizing: border-box;
}
._DraggableComponent-loadingOverlay_1vpvt_42 {
  background: var(--puck-color-white);
  color: var(--puck-color-grey-03);
  border-radius: 4px;
  display: flex;
  padding: 8px;
  top: 8px;
  right: 8px;
  position: absolute;
  pointer-events: all;
  box-sizing: border-box;
  opacity: 0.8;
  z-index: 1;
}
._DraggableComponent_1vpvt_1:hover:not(._DraggableComponent--isLocked_1vpvt_58) > ._DraggableComponent-overlay_1vpvt_29 {
  display: block;
  pointer-events: none;
}
._DraggableComponent--forceHover_1vpvt_64 > ._DraggableComponent-overlay_1vpvt_29 {
  display: block;
  pointer-events: none;
}
._DraggableComponent_1vpvt_1:not(._DraggableComponent--isSelected_1vpvt_69) > ._DraggableComponent-overlay_1vpvt_29 {
  outline: 2px var(--puck-color-azure-09) solid !important;
}
._DraggableComponent--indicativeHover_1vpvt_74 > ._DraggableComponent-overlay_1vpvt_29 {
  display: block;
  background: transparent;
  pointer-events: none;
}
._DraggableComponent_1vpvt_1:not(._DraggableComponent--isSelected_1vpvt_69):has(._DraggableComponent_1vpvt_1:hover > ._DraggableComponent-overlay_1vpvt_29) > ._DraggableComponent-overlay_1vpvt_29 {
  display: none;
}
._DraggableComponent--isSelected_1vpvt_69 {
  outline: 2px var(--puck-color-azure-07) solid !important;
}
._DraggableComponent--isSelected_1vpvt_69 > ._DraggableComponent-overlay_1vpvt_29 {
  background: none;
  display: block;
  position: sticky;
  top: 56px;
  z-index: 1;
}
._DraggableComponent-actions_1vpvt_100 {
  position: absolute;
  right: 6.5px;
  width: auto;
  top: -48px;
  padding: 4px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-radius: 8px;
  background: var(--puck-color-grey-01);
  color: var(--puck-color-white);
  cursor: grab;
  display: none;
  font-family: var(--puck-font-family);
  gap: 4px;
  pointer-events: auto;
  box-sizing: border-box;
}
._DraggableComponent--isSelected_1vpvt_69 > ._DraggableComponent-overlay_1vpvt_29 > ._DraggableComponent-actions_1vpvt_100 {
  display: flex;
}
._DraggableComponent-actionsLabel_1vpvt_125 {
  color: var(--puck-color-grey-08);
  display: flex;
  font-size: var(--puck-font-size-xxxs);
  font-weight: 500;
  justify-content: center;
  align-items: center;
  padding-left: 8px;
  padding-right: 16px;
  margin-right: 8px;
  border-right: 1px solid var(--puck-color-grey-05);
  text-overflow: ellipsis;
  white-space: nowrap;
}
._DraggableComponent-action_1vpvt_100 {
  background: transparent;
  border: none;
  color: var(--puck-color-grey-08);
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 50ms ease-in;
}
._DraggableComponent-action_1vpvt_100:focus-visible {
  outline: 2px solid var(--puck-color-azure-05);
  outline-offset: -2px;
}
@media (hover: hover) and (pointer: fine) {
  ._DraggableComponent-action_1vpvt_100:hover {
    color: var(--puck-color-azure-06);
    transition: none;
  }
}
._DraggableComponent-action_1vpvt_100:active {
  color: var(--puck-color-azure-07);
  transition: none;
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/DropZone/styles.module.css/#css-module-data */
._DropZone_utidm_1 {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  height: 100%;
  outline-offset: -1px;
  width: 100%;
}
._DropZone--zoomEnabled_utidm_10 {
  zoom: 1.33;
}
._DropZone--zoomEnabled_utidm_10 ._DropZone-renderWrapper_utidm_14 {
  zoom: 0.75;
}
._DropZone-content_utidm_18 {
  min-height: 128px;
  height: 100%;
}
._DropZone--userIsDragging_utidm_23 ._DropZone-content_utidm_18 {
  pointer-events: all;
}
._DropZone--userIsDragging_utidm_23:not(._DropZone--draggingOverArea_utidm_27):not(._DropZone--draggingNewComponent_utidm_28) > ._DropZone-content_utidm_18 {
  pointer-events: none;
}
._DropZone--isAreaSelected_utidm_34,
._DropZone--draggingOverArea_utidm_27:not(:has(._DropZone--hoveringOverArea_utidm_35)),
._DropZone--hoveringOverArea_utidm_35:not(._DropZone--isDisabled_utidm_36):not(._DropZone--isRootZone_utidm_37) {
  background: var(--puck-color-azure-11);
  outline: 2px dashed var(--puck-color-azure-08);
}
._DropZone_utidm_1:not(._DropZone--hasChildren_utidm_43) {
  background: var(--puck-color-azure-11);
  outline: 2px dashed var(--puck-color-azure-08);
}
._DropZone--isDestination_utidm_48 {
  outline: 2px dashed var(--puck-color-azure-04) !important;
}
._DropZone--isDestination_utidm_48:not(._DropZone--isRootZone_utidm_37) {
  background: var(--puck-color-azure-10) !important;
}
._DropZone-item_utidm_56 {
  position: relative;
}
._DropZone-hitbox_utidm_60 {
  position: absolute;
  bottom: -12px;
  height: 24px;
  width: 100%;
  z-index: 1;
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/IconButton/IconButton.module.css/#css-module-data */
._IconButton_1xqlg_1 {
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 4px;
  color: currentColor;
  cursor: pointer;
  display: flex;
  font-family: var(--puck-font-family);
  justify-content: center;
  padding: 4px;
  transition: background-color 50ms ease-in, color 50ms ease-in;
}
._IconButton_1xqlg_1:focus-visible {
  outline: 2px solid var(--puck-color-azure-05);
  outline-offset: -2px;
}
@media (hover: hover) and (pointer: fine) {
  ._IconButton_1xqlg_1:hover {
    background: var(--puck-color-azure-12);
    color: var(--puck-color-azure-04);
    transition: none;
  }
}
._IconButton_1xqlg_1:active {
  background: var(--puck-color-azure-11);
  transition: none;
}
._IconButton-title_1xqlg_33 {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(100%);
          clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/SidebarSection/styles.module.css/#css-module-data */
._SidebarSection_125qe_1 {
  display: flex;
  position: relative;
  flex-direction: column;
  color: var(--puck-color-black);
}
._SidebarSection_125qe_1:last-of-type {
  flex-grow: 1;
}
._SidebarSection-title_125qe_12 {
  background: var(--puck-color-white);
  padding: 16px;
  border-bottom: 1px solid var(--puck-color-grey-09);
  border-top: 1px solid var(--puck-color-grey-09);
  overflow-x: auto;
}
._SidebarSection--noBorderTop_125qe_20 > ._SidebarSection-title_125qe_12 {
  border-top: 0px;
}
._SidebarSection-content_125qe_24 {
  padding: 16px;
}
._SidebarSection--noPadding_125qe_28 > ._SidebarSection-content_125qe_24 {
  padding: 0px;
}
._SidebarSection--noPadding_125qe_28 > ._SidebarSection-content_125qe_24:last-child {
  padding-bottom: 4px;
}
._SidebarSection_125qe_1:last-of-type ._SidebarSection-content_125qe_24 {
  border-bottom: none;
  flex-grow: 1;
}
._SidebarSection-breadcrumbLabel_125qe_41 {
  background: none;
  border: 0;
  border-radius: 2px;
  color: var(--puck-color-azure-04);
  cursor: pointer;
  font: inherit;
  flex-shrink: 0;
  padding: 0;
  transition: color 50ms ease-in;
}
._SidebarSection-breadcrumbLabel_125qe_41:focus-visible {
  outline: 2px solid var(--puck-color-azure-05);
  outline-offset: 2px;
}
@media (hover: hover) and (pointer: fine) {
  ._SidebarSection-breadcrumbLabel_125qe_41:hover {
    color: var(--puck-color-azure-03);
    transition: none;
  }
}
._SidebarSection-breadcrumbLabel_125qe_41:active {
  color: var(--puck-color-azure-02);
  transition: none;
}
._SidebarSection-breadcrumbs_125qe_70 {
  align-items: center;
  display: flex;
  gap: 4px;
}
._SidebarSection-breadcrumb_125qe_41 {
  align-items: center;
  display: flex;
  gap: 4px;
}
._SidebarSection-heading_125qe_82 {
  padding-right: 16px;
}
._SidebarSection-loadingOverlay_125qe_86 {
  background: var(--puck-color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 0;
  position: absolute;
  z-index: 1;
  pointer-events: all;
  box-sizing: border-box;
  opacity: 0.8;
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/Heading/styles.module.css/#css-module-data */
._Heading_qxrry_1 {
  display: block;
  color: var(--puck-color-black);
  font-weight: 700;
  margin: 0;
}
._Heading_qxrry_1 b {
  font-weight: 700;
}
._Heading--xxxxl_qxrry_12 {
  font-size: var(--puck-font-size-xxxxl);
  letter-spacing: 0.08ch;
  font-weight: 800;
}
._Heading--xxxl_qxrry_18 {
  font-size: var(--puck-font-size-xxxl);
}
._Heading--xxl_qxrry_22 {
  font-size: var(--puck-font-size-xxl);
}
._Heading--xl_qxrry_26 {
  font-size: var(--puck-font-size-xl);
}
._Heading--l_qxrry_30 {
  font-size: var(--puck-font-size-l);
}
._Heading--m_qxrry_34 {
  font-size: var(--puck-font-size-m);
}
._Heading--s_qxrry_38 {
  font-size: var(--puck-font-size-s);
}
._Heading--xs_qxrry_42 {
  font-size: var(--puck-font-size-xs);
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/MenuBar/styles.module.css/#css-module-data */
._MenuBar_8pf8c_1 {
  background-color: var(--puck-color-white);
  border-bottom: 1px solid var(--puck-color-grey-09);
  display: none;
  left: 0;
  margin-top: 1px;
  padding: 8px 16px;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 2;
}
._MenuBar--menuOpen_8pf8c_14 {
  display: block;
}
@media (min-width: 638px) {
  ._MenuBar_8pf8c_1 {
    border: none;
    display: block;
    margin-top: 0;
    overflow-y: visible;
    padding: 0;
    position: static;
  }
}
._MenuBar-inner_8pf8c_29 {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 8px 16px;
  justify-content: flex-end;
}
@media (min-width: 638px) {
  ._MenuBar-inner_8pf8c_29 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}
._MenuBar-history_8pf8c_45 {
  display: flex;
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/Puck/styles.module.css/#css-module-data */
._Puck_1mnww_19 {
  --puck-frame-width: auto;
  --puck-side-bar-width: 186px;
  --puck-space-px: 16px;
  bottom: 0;
  display: grid;
  font-family: var(--puck-font-family);
  grid-template-areas: "header header header" "left editor right";
  grid-template-columns: 0 var(--puck-frame-width) 0;
  grid-template-rows: min-content auto;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}
._Puck--leftSideBarVisible_1mnww_36 {
  grid-template-columns: var(--puck-side-bar-width) var(--puck-frame-width) 0;
}
._Puck--rightSideBarVisible_1mnww_42 {
  grid-template-columns: 0 var(--puck-frame-width) var(--puck-side-bar-width);
}
._Puck--leftSideBarVisible_1mnww_36._Puck--rightSideBarVisible_1mnww_42 {
  grid-template-columns: var(--puck-side-bar-width) var(--puck-frame-width) var(--puck-side-bar-width);
}
@media (min-width: 458px) {
  ._Puck_1mnww_19 {
    --puck-frame-width: minmax(266px, auto);
  }
}
@media (min-width: 638px) {
  ._Puck_1mnww_19 {
    --puck-side-bar-width: minmax(186px, 250px);
  }
}
@media (min-width: 766px) {
  ._Puck_1mnww_19 {
    --puck-frame-width: auto;
  }
}
@media (min-width: 990px) {
  ._Puck_1mnww_19 {
    --puck-side-bar-width: 256px;
  }
}
@media (min-width: 1198px) {
  ._Puck_1mnww_19 {
    --puck-side-bar-width: 274px;
  }
}
@media (min-width: 1398px) {
  ._Puck_1mnww_19 {
    --puck-side-bar-width: 290px;
  }
}
@media (min-width: 1598px) {
  ._Puck_1mnww_19 {
    --puck-side-bar-width: 320px;
  }
}
._Puck-header_1mnww_96 {
  background: var(--puck-color-white);
  border-bottom: 1px solid var(--puck-color-grey-09);
  color: var(--puck-color-black);
  grid-area: header;
  position: relative;
  max-width: 100vw;
}
._Puck-headerInner_1mnww_105 {
  align-items: end;
  display: grid;
  gap: var(--puck-space-px);
  grid-template-areas: "left middle right";
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: auto;
  padding: var(--puck-space-px);
}
._Puck-headerToggle_1mnww_115 {
  color: var(--puck-color-grey-05);
  display: flex;
  margin-left: -4px;
  padding-top: 2px;
}
._Puck--rightSideBarVisible_1mnww_42 ._Puck-rightSideBarToggle_1mnww_122,
._Puck--leftSideBarVisible_1mnww_36 ._Puck-leftSideBarToggle_1mnww_123 {
  color: var(--puck-color-black);
}
._Puck-headerTitle_1mnww_127 {
  align-self: center;
}
._Puck-headerPath_1mnww_131 {
  font-family: var(--puck-font-family-monospaced);
  font-size: var(--puck-font-size-xxs);
  font-weight: normal;
  word-break: break-all;
}
._Puck-headerTools_1mnww_138 {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
}
._Puck-menuButton_1mnww_144 {
  color: var(--puck-color-grey-05);
  margin-left: -4px;
}
._Puck--menuOpen_1mnww_149 ._Puck-menuButton_1mnww_144 {
  color: var(--puck-color-black);
}
@media (min-width: 638px) {
  ._Puck-menuButton_1mnww_144 {
    display: none;
  }
}
._Puck-leftSideBar_1mnww_123 {
  background: var(--puck-color-grey-12);
  border-right: 1px solid var(--puck-color-grey-09);
  display: flex;
  flex-direction: column;
  grid-area: left;
  overflow-y: auto;
}
._Puck-frame_1mnww_168 {
  display: flex;
  flex-direction: column;
  grid-area: editor;
  overflow: auto;
  position: relative;
}
._Puck-root_1mnww_176 {
  border: 1px solid var(--puck-color-grey-09);
  box-shadow: 0 0 0 calc(var(--puck-space-px) * 1.5) var(--puck-color-grey-11);
  margin: var(--puck-space-px);
  min-width: 321px;
}
@media (min-width: 1198px) {
  ._Puck-root_1mnww_176 {
    margin: calc(var(--puck-space-px) * 1.5);
  }
}
._Puck-rightSideBar_1mnww_122 {
  background: var(--puck-color-white);
  border-left: 1px solid var(--puck-color-grey-09);
  display: flex;
  flex-direction: column;
  grid-area: right;
  overflow-y: auto;
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/InputOrGroup/styles.module.css/#css-module-data */
._Input_1qi5b_1 {
  color: var(--puck-color-grey-04);
  padding: 16px;
  padding-bottom: 12px;
  display: block;
}
._Input_1qi5b_1 ._Input_1qi5b_1 {
  padding: 0px;
}
._Input_1qi5b_1 * {
  box-sizing: border-box;
}
._Input_1qi5b_1 + ._Input_1qi5b_1 {
  border-top: 1px solid var(--puck-color-grey-09);
  margin-top: 8px;
}
._Input_1qi5b_1 ._Input_1qi5b_1 + ._Input_1qi5b_1 {
  border-top: 0px;
  margin-top: 12px;
}
._Input-label_1qi5b_26 {
  align-items: center;
  display: flex;
  padding-bottom: 12px;
  font-size: var(--puck-font-size-xxs);
  font-weight: 600;
}
._Input-labelIcon_1qi5b_34 {
  color: var(--puck-color-grey-07);
  display: flex;
  margin-right: 4px;
  padding-left: 4px;
}
._Input-disabledIcon_1qi5b_41 {
  color: var(--puck-color-grey-05);
  margin-left: auto;
}
._Input-input_1qi5b_46 {
  background: var(--puck-color-white);
  border-width: 1px;
  border-style: solid;
  border-color: var(--puck-color-grey-09);
  border-radius: 4px;
  font-family: inherit;
  font-size: 14px;
  padding: 12px 15px;
  transition: border-color 50ms ease-in;
  width: 100%;
}
select._Input-input_1qi5b_46 {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23c3c3c3'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
  background-size: 12px;
  background-position: calc(100% - 12px) calc(50% + 3px);
  background-repeat: no-repeat;
  background-color: var(--puck-color-white);
  cursor: pointer;
}
@media (hover: hover) and (pointer: fine) {
  ._Input_1qi5b_1:has(> input):hover ._Input-input_1qi5b_46:not([readonly]),
  ._Input_1qi5b_1:has(> textarea):hover ._Input-input_1qi5b_46:not([readonly]) {
    border-color: var(--puck-color-grey-05);
    transition: none;
  }
  ._Input_1qi5b_1:has(> select):hover ._Input-input_1qi5b_46:not([disabled]) {
    background-color: var(--puck-color-azure-12);
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%235a5a5a'><polygon points='0,0 100,0 50,50'/></svg>");
    border-color: var(--puck-color-grey-05);
    transition: none;
  }
}
._Input-input_1qi5b_46:focus {
  border-color: var(--puck-color-grey-05);
  outline: 2px solid var(--puck-color-azure-05);
  transition: none;
}
._Input--readOnly_1qi5b_90 > ._Input-input_1qi5b_46,
._Input--readOnly_1qi5b_90 > select._Input-input_1qi5b_46 {
  background-color: var(--puck-color-grey-11);
  border-color: var(--puck-color-grey-09);
  color: var(--puck-color-grey-04);
  cursor: default;
  opacity: 1;
  outline: 0;
  transition: none;
}
._Input-radioGroupItems_1qi5b_101 {
  display: flex;
  border: 1px solid var(--puck-color-grey-09);
  border-radius: 4px;
  flex-wrap: wrap;
}
._Input-radio_1qi5b_101 {
  border-right: 1px solid var(--puck-color-grey-09);
  flex-grow: 1;
}
._Input-radio_1qi5b_101:first-of-type {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
._Input-radio_1qi5b_101:first-of-type ._Input-radioInner_1qi5b_118 {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
._Input-radio_1qi5b_101:last-of-type {
  border-bottom-right-radius: 4px;
  border-right: 0;
  border-top-right-radius: 4px;
}
._Input-radio_1qi5b_101:last-of-type ._Input-radioInner_1qi5b_118 {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
._Input-radioInner_1qi5b_118 {
  background-color: var(--puck-color-white);
  color: var(--puck-color-grey-04);
  cursor: pointer;
  font-size: var(--puck-font-size-xxxs);
  padding: 8px 12px;
  text-align: center;
  transition: background-color 50ms ease-in;
}
._Input-radio_1qi5b_101:has(:focus-visible) {
  outline: 2px solid var(--puck-color-azure-05);
  outline-offset: 2px;
  position: relative;
}
@media (hover: hover) and (pointer: fine) {
  ._Input-radioInner_1qi5b_118:hover {
    background-color: var(--puck-color-azure-12);
    transition: none;
  }
}
._Input--readOnly_1qi5b_90 ._Input-radioInner_1qi5b_118 {
  background-color: var(--puck-color-white);
  color: var(--puck-color-grey-04);
  cursor: default;
}
._Input-radio_1qi5b_101 ._Input-radioInput_1qi5b_163:checked ~ ._Input-radioInner_1qi5b_118 {
  background-color: var(--puck-color-azure-11);
  color: var(--puck-color-azure-04);
  font-weight: 500;
}
._Input--readOnly_1qi5b_90 ._Input-radioInput_1qi5b_163:checked ~ ._Input-radioInner_1qi5b_118 {
  background-color: var(--puck-color-grey-11);
  color: var(--puck-color-grey-04);
}
._Input-radio_1qi5b_101 ._Input-radioInput_1qi5b_163 {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(100%);
          clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
textarea._Input-input_1qi5b_46 {
  margin-bottom: -4px;
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/InputOrGroup/fields/ArrayField/styles.module.css/#css-module-data */
._ArrayField_1txra_5 {
  display: flex;
  flex-direction: column;
  background-color: var(--puck-color-grey-09);
  border: 1px solid var(--puck-color-grey-09);
  border-radius: 4px;
}
._ArrayField--isDraggingFrom_1txra_13 {
  background-color: var(--puck-color-azure-11);
}
._ArrayField-addButton_1txra_17 {
  background-color: var(--puck-color-white);
  border: none;
  border-radius: 4px;
  display: flex;
  color: var(--puck-color-azure-05);
  justify-content: center;
  cursor: pointer;
  width: 100%;
  margin: 0;
  padding: 16px;
  text-align: left;
  transition: background-color 50ms ease-in;
}
._ArrayField--hasItems_1txra_32 > ._ArrayField-addButton_1txra_17 {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
._ArrayField-addButton_1txra_17:focus-visible {
  outline: 2px solid var(--puck-color-azure-05);
  outline-offset: 2px;
  position: relative;
}
@media (hover: hover) and (pointer: fine) {
  ._ArrayField_1txra_5:not(._ArrayField--isDraggingFrom_1txra_13) > ._ArrayField-addButton_1txra_17:hover {
    background: var(--puck-color-azure-12);
    color: var(--puck-color-azure-04);
    transition: none;
  }
}
._ArrayField_1txra_5:not(._ArrayField--isDraggingFrom_1txra_13) > ._ArrayField-addButton_1txra_17:active {
  background: var(--puck-color-azure-11);
  color: var(--puck-color-azure-04);
  transition: none;
}
._ArrayFieldItem_1txra_61 {
  background: var(--puck-color-white);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: block;
  margin-bottom: 1px;
}
._ArrayField--isDraggingFrom_1txra_13 > ._ArrayFieldItem_1txra_61:not(._ArrayFieldItem--isDragging_1txra_69) {
  border-bottom: 1px solid var(--puck-color-grey-09);
  margin-bottom: 0;
}
._ArrayFieldItem--isExpanded_1txra_74 {
  border-bottom: 0;
  outline-offset: 0px !important;
  outline: 1px solid var(--puck-color-azure-07) !important;
}
._ArrayFieldItem--isDragging_1txra_69 {
  box-shadow: color-mix(in srgb, var(--puck-color-grey-06) 25%, transparent) 0 3px 6px 0;
}
._ArrayFieldItem--isDragging_1txra_69 ._ArrayFieldItem-summary_1txra_84:active {
  background-color: var(--puck-color-white);
}
._ArrayFieldItem_1txra_61 + ._ArrayFieldItem_1txra_61 {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
._ArrayFieldItem-summary_1txra_84 {
  color: var(--puck-color-grey-04);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 2px;
  justify-content: space-between;
  font-size: var(--puck-font-size-xxs);
  list-style: none;
  padding: 12px 15px;
  position: relative;
  overflow: hidden;
  transition: background-color 50ms ease-in;
}
._ArrayFieldItem--readOnly_1txra_108 > ._ArrayFieldItem-summary_1txra_84 {
  background-color: var(--puck-color-grey-12);
  color: var(--puck-color-grey-06);
}
._ArrayFieldItem_1txra_61:first-of-type > ._ArrayFieldItem-summary_1txra_84 {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
._ArrayFieldItem-summary_1txra_84:focus-visible {
  outline: 2px solid var(--puck-color-azure-05);
  outline-offset: 2px;
}
@media (hover: hover) and (pointer: fine) {
  ._ArrayFieldItem-summary_1txra_84:hover {
    background-color: var(--puck-color-azure-12);
    transition: none;
  }
}
._ArrayFieldItem-summary_1txra_84:active {
  background-color: var(--puck-color-azure-11);
  transition: none;
}
._ArrayFieldItem--isExpanded_1txra_74 > ._ArrayFieldItem-summary_1txra_84 {
  background: var(--puck-color-azure-11);
  color: var(--puck-color-azure-04);
  font-weight: 600;
  transition: none;
}
._ArrayFieldItem-body_1txra_142 {
  display: none;
}
._ArrayFieldItem--isExpanded_1txra_74 > ._ArrayFieldItem-body_1txra_142 {
  display: block;
}
._ArrayFieldItem-fieldset_1txra_150 {
  border: none;
  border-top: 1px solid var(--puck-color-grey-09);
  margin: 0;
  padding: 16px 15px;
}
._ArrayFieldItem-rhs_1txra_157 {
  display: flex;
  gap: 4px;
  align-items: center;
}
._ArrayFieldItem-actions_1txra_163 {
  color: var(--puck-color-grey-04);
  display: flex;
  gap: 4px;
  opacity: 0;
}
._ArrayFieldItem-summary_1txra_84:focus-within > ._ArrayFieldItem-rhs_1txra_157 > ._ArrayFieldItem-actions_1txra_163,
._ArrayFieldItem-summary_1txra_84:hover > ._ArrayFieldItem-rhs_1txra_157 > ._ArrayFieldItem-actions_1txra_163 {
  opacity: 1;
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/ExternalInput/styles.module.css/#css-module-data */
._ExternalInput-actions_q3l5a_1 {
  display: flex;
}
._ExternalInput-button_q3l5a_5 {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  background-color: var(--puck-color-white);
  border: 1px solid var(--puck-color-grey-09);
  border-radius: 4px;
  color: var(--puck-color-azure-04);
  cursor: pointer;
  padding: 12px 16px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: background-color 50ms ease-in;
  position: relative;
  overflow: hidden;
  flex-grow: 1;
}
._ExternalInput--dataSelected_q3l5a_25 ._ExternalInput-button_q3l5a_5 {
  color: var(--puck-color-grey-03);
  display: block;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
._ExternalInput-detachButton_q3l5a_32 {
  border: 1px solid var(--puck-color-grey-09);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: var(--puck-color-grey-12);
  color: var(--puck-color-grey-05);
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  position: relative;
  transition: background-color 50ms ease-in, color 50ms ease-in;
  margin-left: -1px;
}
._ExternalInput-button_q3l5a_5:focus-visible,
._ExternalInput-detachButton_q3l5a_32:focus-visible {
  outline: 2px solid var(--puck-color-azure-05);
  outline-offset: 2px;
  z-index: 1;
}
@media (hover: hover) and (pointer: fine) {
  ._ExternalInput-button_q3l5a_5:hover,
  ._ExternalInput-detachButton_q3l5a_32:hover {
    background: var(--puck-color-azure-12);
    transition: none;
  }
  ._ExternalInput-detachButton_q3l5a_32:hover {
    color: var(--puck-color-azure-04);
  }
}
._ExternalInput-button_q3l5a_5:active,
._ExternalInput-detachButton_q3l5a_32:active {
  background: var(--puck-color-azure-11);
  transition: none;
}
._ExternalInputModal_q3l5a_74 {
  color: var(--puck-color-black);
  display: grid;
  grid-template-rows: min-content minmax(128px, 100%) min-content;
  grid-template-columns: 100%;
  position: relative;
  min-height: 50vh;
  max-height: 90vh;
}
._ExternalInputModal-grid_q3l5a_84 {
  display: flex;
  flex-direction: column;
}
@media (min-width: 458px) {
  ._ExternalInputModal-grid_q3l5a_84 {
    display: grid;
    grid-template-columns: 100%;
  }
  ._ExternalInputModal--filtersToggled_q3l5a_95 ._ExternalInputModal-grid_q3l5a_84 {
    grid-template-columns: 25% 75%;
  }
}
._ExternalInputModal-filters_q3l5a_100 {
  border-bottom: 1px solid var(--puck-color-grey-09);
}
._ExternalInputModal--filtersToggled_q3l5a_95 ._ExternalInputModal-filters_q3l5a_100 {
  display: none;
}
@media (min-width: 458px) {
  ._ExternalInputModal-filters_q3l5a_100 {
    border-right: 1px solid var(--puck-color-grey-09);
    display: none;
  }
  ._ExternalInputModal--filtersToggled_q3l5a_95 ._ExternalInputModal-filters_q3l5a_100 {
    display: block;
  }
}
._ExternalInputModal-masthead_q3l5a_119 {
  background-color: var(--puck-color-grey-12);
  border-bottom: 1px solid var(--puck-color-grey-09);
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  padding: 24px;
}
._ExternalInputModal-tableWrapper_q3l5a_128 {
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  flex-grow: 1;
}
._ExternalInputModal-table_q3l5a_128 {
  border-collapse: unset;
  border-spacing: 0px;
  color: var(--puck-color-grey-02);
  position: relative;
  z-index: 0;
  min-width: 100%;
}
._ExternalInputModal-thead_q3l5a_144 {
  background-color: var(--puck-color-white);
  position: sticky;
  top: 0;
  z-index: 1;
}
._ExternalInputModal-th_q3l5a_144 {
  border-bottom: 1px solid var(--puck-color-grey-09);
  color: var(--puck-color-grey-04);
  font-weight: 500;
  font-size: 14px;
  padding: 16px 24px;
}
._ExternalInputModal-td_q3l5a_159 {
  border-bottom: 1px solid var(--puck-color-grey-10);
  padding: 16px 24px;
}
._ExternalInputModal-tr_q3l5a_164 ._ExternalInputModal-td_q3l5a_159:first-of-type {
  font-weight: 500;
  width: 1%;
  white-space: nowrap;
}
@media (hover: hover) and (pointer: fine) {
  ._ExternalInputModal-tbody_q3l5a_171 ._ExternalInputModal-tr_q3l5a_164:hover {
    background: var(--puck-color-azure-12);
    color: var(--puck-color-azure-04);
    cursor: pointer;
    position: relative;
    margin-left: -5px;
  }
  ._ExternalInputModal-tbody_q3l5a_171 ._ExternalInputModal-tr_q3l5a_164:hover ._ExternalInputModal-td_q3l5a_159:first-of-type {
    border-left: 4px solid var(--puck-color-azure-04);
    padding-left: 20px;
  }
}
._ExternalInputModal-tbody_q3l5a_171 ._ExternalInputModal-tr_q3l5a_164:last-of-type ._ExternalInputModal-td_q3l5a_159 {
  border-bottom: none;
}
._ExternalInputModal-tableWrapper_q3l5a_128 {
  display: none;
}
._ExternalInputModal--hasData_q3l5a_197 ._ExternalInputModal-tableWrapper_q3l5a_128 {
  display: block;
}
._ExternalInputModal-loadingBanner_q3l5a_201 {
  display: none;
  background-color: color-mix(in srgb, var(--puck-color-white) 90%, transparent);
  padding: 64px;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
._ExternalInputModal--isLoading_q3l5a_218 ._ExternalInputModal-loadingBanner_q3l5a_201 {
  display: flex;
}
._ExternalInputModal-searchForm_q3l5a_222 {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  flex-grow: 1;
}
@media (min-width: 458px) {
  ._ExternalInputModal-searchForm_q3l5a_222 {
    flex-wrap: nowrap;
  }
}
._ExternalInputModal-search_q3l5a_222 {
  display: flex;
  background: var(--puck-color-white);
  border-width: 1px;
  border-style: solid;
  border-color: var(--puck-color-grey-09);
  border-radius: 4px;
  flex-grow: 1;
  transition: border-color 50ms ease-in;
}
._ExternalInputModal-search_q3l5a_222:focus-within {
  border-color: var(--puck-color-grey-05);
  outline: 2px solid var(--puck-color-azure-05);
  transition: none;
}
@media (hover: hover) and (pointer: fine) {
  ._ExternalInputModal-search_q3l5a_222:hover {
    border-color: var(--puck-color-grey-05);
    transition: none;
  }
}
._ExternalInputModal-searchIcon_q3l5a_259 {
  align-items: center;
  background: var(--puck-color-grey-12);
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  border-right: 1px solid var(--puck-color-grey-09);
  color: var(--puck-color-grey-07);
  display: flex;
  justify-content: center;
  padding: 12px 15px;
  transition: color 50ms ease-in;
}
._ExternalInputModal-search_q3l5a_222:focus-within ._ExternalInputModal-searchIcon_q3l5a_259 {
  color: var(--puck-color-grey-04);
  transition: none;
}
@media (hover: hover) and (pointer: fine) {
  ._ExternalInputModal-search_q3l5a_222:hover ._ExternalInputModal-searchIcon_q3l5a_259 {
    color: var(--puck-color-grey-04);
    transition: none;
  }
}
._ExternalInputModal-searchIconText_q3l5a_284 {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(100%);
          clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
._ExternalInputModal-searchInput_q3l5a_294 {
  border: none;
  border-radius: 4px;
  background: var(--puck-color-white);
  font-family: inherit;
  font-size: 14px;
  padding: 12px 15px;
  width: 100%;
}
._ExternalInputModal-searchInput_q3l5a_294:focus {
  outline: 0;
}
._ExternalInputModal-searchActions_q3l5a_308 {
  display: flex;
  gap: 8px;
  height: 44px;
  width: 100%;
}
@media (min-width: 458px) {
  ._ExternalInputModal-searchActions_q3l5a_308 {
    width: auto;
  }
}
._ExternalInputModal-searchActionIcon_q3l5a_321 {
  align-self: center;
}
._ExternalInputModal-footer_q3l5a_325 {
  background-color: var(--puck-color-grey-12);
  border-top: 1px solid var(--puck-color-grey-09);
  color: var(--puck-color-grey-04);
  font-weight: 500;
  font-size: 14px;
  padding: 16px;
  text-align: right;
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/Modal/styles.module.css/#css-module-data */
._Modal_zud0i_1 {
  background: color-mix(in srgb, var(--puck-color-black) 75%, transparent);
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  padding: 32px;
}
._Modal--isOpen_zud0i_15 {
  display: flex;
}
._Modal-inner_zud0i_19 {
  width: 100%;
  max-width: 1024px;
  border-radius: 8px;
  overflow: hidden;
  background: var(--puck-color-white);
  display: flex;
  flex-direction: column;
  max-height: 90vh;
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/InputOrGroup/fields/ObjectField/styles.module.css/#css-module-data */
._ObjectField_15j63_5 {
  display: flex;
  flex-direction: column;
  background-color: var(--puck-color-white);
  border: 1px solid var(--puck-color-grey-09);
  border-radius: 4px;
}
._ObjectField-fieldset_15j63_13 {
  border: none;
  margin: 0;
  padding: 16px 15px;
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/Puck/components/Fields/styles.module.css/#css-module-data */
._PuckFields_17k3p_1 {
  position: relative;
  font-family: var(--puck-font-family);
}
._PuckFields-loadingOverlay_17k3p_6 {
  background: var(--puck-color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 0;
  position: absolute;
  z-index: 1;
  pointer-events: all;
  box-sizing: border-box;
  opacity: 0.8;
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/ComponentList/styles.module.css/#css-module-data */
._ComponentList_odh9d_1 {
  max-width: 100%;
}
._ComponentList--isExpanded_odh9d_5 + ._ComponentList_odh9d_1 {
  margin-top: 12px;
}
._ComponentList-content_odh9d_9 {
  display: none;
}
._ComponentList--isExpanded_odh9d_5 > ._ComponentList-content_odh9d_9 {
  display: block;
}
._ComponentList-title_odh9d_17 {
  background-color: transparent;
  border: 0;
  color: var(--puck-color-grey-05);
  cursor: pointer;
  display: flex;
  font: inherit;
  font-size: var(--puck-font-size-xxxs);
  list-style: none;
  margin-bottom: 6px;
  padding: 8px;
  text-transform: uppercase;
  transition: background-color 50ms ease-in, color 50ms ease-in;
  gap: 4px;
  border-radius: 4px;
  width: 100%;
}
._ComponentList-title_odh9d_17:focus-visible {
  outline: 2px solid var(--puck-color-azure-05);
  outline-offset: 2px;
}
@media (hover: hover) and (pointer: fine) {
  ._ComponentList-title_odh9d_17:hover {
    background-color: var(--puck-color-azure-11);
    color: var(--puck-color-azure-04);
    transition: none;
  }
}
._ComponentList-title_odh9d_17:active {
  background-color: var(--puck-color-azure-10);
  transition: none;
}
._ComponentList-titleIcon_odh9d_53 {
  margin-left: auto;
}

/* css-module:/home/runner/work/puck/puck/packages/core/components/LayerTree/styles.module.css/#css-module-data */
._LayerTree_1pgw8_1 {
  color: var(--puck-color-grey-03);
  font-family: var(--puck-font-family);
  font-size: var(--puck-font-size-xxs);
  margin: 0;
  position: relative;
  list-style: none;
  padding: 0;
}
._LayerTree-zoneTitle_1pgw8_11 {
  color: var(--puck-color-grey-05);
  font-size: var(--puck-font-size-xxxs);
  text-transform: uppercase;
}
._LayerTree-helper_1pgw8_17 {
  text-align: center;
  color: var(--puck-color-grey-07);
  margin: 8px 4px;
}
._Layer_1pgw8_1 {
  position: relative;
  border: 1px solid transparent;
  border-radius: 4px;
}
._Layer-inner_1pgw8_29 {
  border: 1px solid transparent;
  border-radius: 4px;
  transition: color 50ms ease-in;
}
._Layer--containsZone_1pgw8_35 > ._Layer-inner_1pgw8_29 {
  padding-left: 0;
}
._Layer-clickable_1pgw8_39 {
  align-items: center;
  background: none;
  border: 0;
  border-radius: 4px;
  color: inherit;
  cursor: pointer;
  display: flex;
  font: inherit;
  padding-left: 12px;
  padding-right: 4px;
  width: 100%;
}
._Layer-clickable_1pgw8_39:focus-visible {
  outline: 2px solid var(--puck-color-azure-05);
  outline-offset: 2px;
  position: relative;
  z-index: 1;
}
@media (hover: hover) and (pointer: fine) {
  ._Layer_1pgw8_1:not(._Layer--isSelected_1pgw8_61) > ._Layer-inner_1pgw8_29:hover {
    border-color: var(--puck-color-azure-10);
    background: var(--puck-color-azure-11);
    color: var(--puck-color-azure-04);
    transition: none;
  }
}
._Layer--isSelected_1pgw8_61 {
  border-color: var(--puck-color-azure-08);
}
._Layer--isSelected_1pgw8_61 > ._Layer-inner_1pgw8_29 {
  background: var(--puck-color-azure-10);
}
._Layer--isSelected_1pgw8_61 > ._Layer-inner_1pgw8_29 > ._Layer-clickable_1pgw8_39 > ._Layer-chevron_1pgw8_77,
._Layer--childIsSelected_1pgw8_78 > ._Layer-inner_1pgw8_29 > ._Layer-clickable_1pgw8_39 > ._Layer-chevron_1pgw8_77 {
  transform: scaleY(-1);
}
._Layer-zones_1pgw8_82 {
  display: none;
  margin-left: 12px;
}
._Layer--isSelected_1pgw8_61 > ._Layer-zones_1pgw8_82,
._Layer--childIsSelected_1pgw8_78 > ._Layer-zones_1pgw8_82 {
  display: block;
}
._Layer-zones_1pgw8_82 > ._LayerTree_1pgw8_1 {
  margin-left: 12px;
}
._Layer-title_1pgw8_96,
._LayerTree-zoneTitle_1pgw8_11 {
  display: flex;
  gap: 8px;
  align-items: center;
  margin: 8px 4px;
  overflow-x: hidden;
}
._Layer-name_1pgw8_105 {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
._Layer-icon_1pgw8_111 {
  color: var(--puck-color-rose-07);
  margin-top: 4px;
}
._Layer-zoneIcon_1pgw8_116 {
  color: var(--puck-color-grey-08);
  margin-top: 4px;
}

[class^="_DraggableComponent-overlay"] {
  z-index: 9999 !important;
}

/* add style for puck-drop-zone children */
#puck-drop-zone > :not([hidden]) ~ :not([hidden]) {
  padding-top: 16px;
  padding-bottom: 16px;
}

@font-face {
font-family: '__matter_ee34e8';
src: url(/_next/static/media/1865298acede3ba8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__matter_ee34e8';
src: url(/_next/static/media/7e4f0097f7201fe9-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__matter_ee34e8';
src: url(/_next/static/media/c9c442a0db8ba337-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__matter_ee34e8';
src: url(/_next/static/media/e092e86a5c91acb2-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__matter_Fallback_ee34e8';src: local("Arial");ascent-override: 87.97%;descent-override: 18.49%;line-gap-override: 0.00%;size-adjust: 102.76%
}.__className_ee34e8 {font-family: '__matter_ee34e8', '__matter_Fallback_ee34e8'
}

